import { parseMedicines } from "../../services/parseMedicines"

const printDateValue = ({ date }) => {
  if (!date.month.value || !date.date.value || !date.year) return "N/A"
  return `${date.month.value} ${date.date.value} ${
    date.year?.value || date.year
  }`
}

export function orderTicketBody(request) {
  let {
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    medicines,
    deliveryAddress,
    deliveryFee,
    paymentOption,
    changeFor,
    creditCardBank,
    creditCardType,
    dispensingChannel,
    orderNotes,
    courierOption,
    preferredDeliveryDate,
    preferredDeliveryTime,
    doctorName,
    hospitalName,
    otherHospital,
    authorizedPerson,
    isSameDay,
    contactPerson,
    bankName,
    products,
    agreeContactPerson,
    feedback,
    rating,
    submittingUserType,
    prescriptionDate,
  } = request

  const medicineBody = parseMedicines({ medicines, products })

  let contactPersonName =
    contactPerson.length === 0
      ? authorizedPerson.fullName
      : `${firstName} ${lastName}`

  let contactPersonNumber =
    contactPerson.length === 0 ? authorizedPerson.contactNumber : mobileNumber

  return `First name: ${firstName}
  Last name: ${lastName}
  Mobile number: ${mobileNumber}
  Email: ${emailAddress}
  Prescribing Doctor: ${doctorName} 
  Prescription Date: ${printDateValue({ date: prescriptionDate })}
  Hospital Name: ${
    hospitalName?.value === "Other" ? otherHospital : hospitalName?.value
  }
  Street address: ${deliveryAddress.streetAddress} 
  City/Municipality: ${deliveryAddress.city.value}
  Province: ${deliveryAddress.province.value}  
  Barangay: ${deliveryAddress?.barangay?.value}
  Deliver to Hospital: ${deliveryAddress?.deliveryToHospital?.value || "N/A"}  
  Address type: ${deliveryAddress.addressType} 
  ZIP Code: ${deliveryAddress?.zipCode?.value || "N/A"} 
  Preferred Delivery Date: ${
    isSameDay === "Yes"
      ? "Same day delivery"
      : printDateValue({ date: preferredDeliveryDate })
  } 
  Preferred Delivery Time: ${
    isSameDay === "Yes" ? "Same day delivery" : preferredDeliveryTime
  } 
  Authorized Person to receive: ${contactPersonName} 
  Contact number of authorized person: ${contactPersonNumber}
  ✅ ${agreeContactPerson?.join()} 
  Rating: ${rating?.value || "N/A"}
  Feedback: ${feedback || "N/A"}
  Delivery notes: ${deliveryAddress?.notes}
  Ordering user: ${submittingUserType || "N/A"}
  
  Medicines ordered:
  ${medicineBody}

  If your preferred dispensing channel encounters challenges delivering your order: ${
    dispensingChannel || ""
  }
  Order notes: ${orderNotes || "N/A"}
  Preferred courier: ${courierOption} 
  Payment method: ${paymentOption?.value || "N/A"}
  Change For: ${paymentOption?.value === "Cash on Delivery" ? changeFor : "N/A"}
  Delivery Fee: ${deliveryFee || 0}`
}
